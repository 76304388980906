import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Footer, GoogleNoIndex, PageTitle } from '../components/shared';
import { faFileCheck, faFileLines, faFileSignature } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { getApiClient } from '../utils/GetApiClient';

const DownloadPage = () => {
  const [accessKey, setAccessKey] = React.useState('');
  const [validateResult, setValidateResult] = React.useState({} as any);

  React.useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    setAccessKey(params.get('key'));

    getApiClient()
      .get<any>('/api/reports/validate/' + params.get('key'))
      .then((response) => {
        setValidateResult(response.data);
      })
      .catch((error) => {
        document.location.href = '/';
      });
  }, []);

  return (
    <>
      <PageTitle subtitle="Download" />
      <GoogleNoIndex />
      <Container className="wsmainwp text-center mt-50" style={{ minHeight: '89.3vh' }}>
        <StaticImage src="../images/ptp-logo.png" className="img-fluid" width={500} alt="logo image" placeholder="none" />
        <h2 className="mt-30 fw-normal">Thank you again for ordering your custom-built Property Tax Playbook!</h2>
        <h2 className="mt-30">Here are your unique property-specific Playbook downloads:</h2>
        <h4 className="mt-50 purple-color">Order Number: {validateResult.orderId}</h4>
        <h4 className="mt-10 purple-color">Address: {validateResult.situs}</h4>
        <Row className="mt-50">
          <Col>
            <a href="/Playbook Instructions - DO NOT MAIL.pdf" download="Playbook Instructions - DO NOT MAIL.pdf" target="_blank" className="download-link">
              <FontAwesomeIcon icon={faFileLines} size="5x" />
              <h3 className="mt-30">Playbook Instructions</h3>
            </a>
          </Col>
          <Col>
            {validateResult && (
              <a href={process.env.GATSBY_APP_API_URL + '/api/reports/download/mail-in/' + accessKey} className="download-link" rel="noreferrer">
                <FontAwesomeIcon icon={faFileSignature} size="5x" />
                <h3 className="mt-30">Mail-In Evidence</h3>
              </a>
            )}
          </Col>
          <Col>
            {validateResult && (
              <a href={process.env.GATSBY_APP_API_URL + '/api/reports/download/e-file/' + accessKey} className="download-link" rel="noreferrer">
                <FontAwesomeIcon icon={faFileCheck} size="5x" />
                <h3 className="mt-30">E-File Evidence</h3>
              </a>
            )}
          </Col>
        </Row>
        {validateResult.county === 'Denton' && (
          <>
            <Row className="mt-40 text-start div-xl">
              <Col md="3">
                <div className="fw-bold text-decoration-underline">Mail Your Appeal To:</div>
                Denton CAD
                <br />
                c/o ARB Notice of Protest
                <br />
                3911 MORSE ST.
                <br />
                DENTON, TX 76208
              </Col>
              <Col md="4">
                <div className="fw-bold text-decoration-underline">Or You Can E-File At This Link:</div>
                <a href="https://eprotest.dentoncad.com/" target="_blank" rel="noreferrer" className="text-decoration-underline purple-color">
                  https://eprotest.dentoncad.com/
                </a>
                <div className="mt-20 fw-bold fst-italic">
                  Please Note: Denton protests must be filed or postmarked on or before June 15, 2022. (For most taxpayers)
                </div>
              </Col>
              <Col md="5">
                <div className="fst-italic">
                  For last minute filings or if you can't find your notice of value to e-file, you can visit this link to submit a contact form:
                </div>
                <a
                  href="https://www.dentoncad.com/index.php/Contact-Form"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-underline purple-color"
                  style={{ fontSize: 16 }}
                >
                  https://www.dentoncad.com/index.php/Contact-Form
                </a>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};
export default DownloadPage;
